<template>
    <div class="container">
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column align-items-start">
                <h2>All users</h2><span class="text-muted">list of all users</span>
            </div>
            <router-link to="/createprofile" class="btn btn-primary">Create new user</router-link>
        </div>
        <div class="table-responsive mt-5 bg-white">
            <div class="text-center" v-if="isLoading">Fetching Users</div>
            <table v-if="!isLoading && users.length" class="table">
                <thead>
                  <tr>
                      <th>SN</th>
                      <th>Address</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Zip</th>
                      <th></th>
                  </tr>
                </thead>
                <tbody>
                <tr v-for="(user, index) in users" :key="index">
                    <td>{{index+1}}</td>
                    <td>{{user.address}}</td>
                    <td>{{user.city}}</td>
                    <td>{{user.state}}</td>
                    <td>{{user.zip}}</td>
                    <td>
                        <div class="button-group">
                            <button class="btn btn-sm">view</button>
                            <!-- <router-link :to="`/editprofile/${user.id}`" class="btn btn-sm btn-secondary">edit</router-link> -->
                            <router-link :to="`/editprofile`" class="btn btn-sm btn-secondary">edit</router-link>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="text-center" v-if="!isLoading && !users.length">No users found</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import userFormSchema from '../../forms/userFormSchema'

export default {
  name: 'Users',
  schema: userFormSchema,
  mounted () {
    this.fetchUsers()
  },
  data () {
    return {
      users: [],
      isLoading: true
    }
  },
  methods: {
    async fetchUsers () {
      try {
        this.users = (await axios.get(`${window.hostname}/userprofile/${this.$auth.user.email}`)).data
      } catch (e) {
        console.log(e)
        alert(e.message)
      }
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.vue-form-generator > div{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 1;
}

.form-group{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 2%;
  width: 50%;
}

.field-wrap, .wrapper{
  width: 100%;
}

.dropList{
  z-index: 10;
  background-color: #FFF;
  position: relative;
  width: 40%;
  top: 5px;
  right: 12px;
}

legend{
  margin: 10px 0 20px 18px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.hint{
  font-size: 10px;
  font-style: italic;
  color: purple;
}

.help-block{
  color: red;
}
</style>