<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info" class="navbar-fixed-top">
      <b-navbar-brand style="font-style: oblique; font-weight: bold;" href="/" class="font-size">Morgan Family Trust</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items class="ml-auto"-->
        <b-navbar-nav class="mx-auto font-size">
          <b-nav-item-dropdown text="Family Pages" right>
            <b-dropdown-item href="#">Morgan Family</b-dropdown-item>
            <b-dropdown-item href="#">Clarke Family</b-dropdown-item>
            <b-dropdown-item href="#">Davis Family</b-dropdown-item>
            <b-dropdown-item href="/toddfamily">Todd Family</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="College Grants" right>
            <b-dropdown-item href="/grantinfo">Grant Info</b-dropdown-item>
            <b-dropdown-item v-if="$auth.isAuthenticated" href="/apply">Apply</b-dropdown-item>
            <b-dropdown-item href="/applicationstatus">Status</b-dropdown-item>
            <b-dropdown-item href="/applicationreview">Review</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="Family Reunion" right>
            <b-dropdown-item href="#">2019 Keystone CO</b-dropdown-item>
            <b-dropdown-item href="#">2015</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-navbar-nav>
            <b-nav-item href="https://www.bankofamerica.com" target="_blank">Financial Link</b-nav-item>
            <b-nav-item to="/about">Board</b-nav-item>
            <!-- <b-nav-item to="/admin">Admin</b-nav-item> !$auth.isAuthenticated && !$auth.loading -->
            <div v-if="!$auth.loading">
              <b-nav-item-dropdown v-if="$auth.isAuthenticated" text="Admin" right>
                <b-dropdown-item href="/usermanagement">User Management</b-dropdown-item>
                <b-dropdown-item href="#">Family News</b-dropdown-item>
              </b-nav-item-dropdown>  
            </div>
            <!-- <b-nav-item-dropdown v-if="show_admin_menu" text="Admin" right>
              <b-dropdown-item href="/usermanagement">User Management</b-dropdown-item>
              <b-dropdown-item href="#">Family News</b-dropdown-item>
            </b-nav-item-dropdown> -->
            
          </b-navbar-nav>


        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-navbar-brand href="https://www.facebook.com/groups/126085417508252" target="_blank">
            <img src="@/assets/facebook3.png" alt="Kitten">
          </b-navbar-brand>
          <!-- style="font-size: 1.5em !important;" -->
          <b-nav-item-dropdown right class="font-size">
            <template v-slot:button-content>
              <em>User</em>
            </template>
            <!-- <b-dropdown-item href="/users">Users</b-dropdown-item> -->
            <b-dropdown-item href="/editprofile">Profile</b-dropdown-item>
            <div v-if="!$auth.loading">
              <b-dropdown-item
                v-if="!$auth.isAuthenticated"
                @click="login"
                class="button is-dark"
                ><strong>Sign in</strong></b-dropdown-item
              >
              <b-dropdown-item
                v-if="$auth.isAuthenticated"
                @click="logout"
                class="button is-dark"
                ><strong>Log out</strong></b-dropdown-item
              >
            </div>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import axios from 'axios';
import Store from "../store/backup"
export default {
  name: 'TopHeader',
  props: ["show_admin_menu"],
  data() {
    return {
      user: '',
      access: false
    };
  },
  methods: {
    // decrement() {
    //     this.$store.commit('decrement')
    // },
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
      // this.$store.dispatch('login')
    },
    // Log the user out
    logout() {
      // this.decrement();

      // this.decrement().then((result) => {
      //   this.$auth.logout({
      //     returnTo: window.location.origin
      //   });
      // }).catch((err) => {
      //   console.log("logout error: ", err)
      // });

      this.$auth.logout({
        returnTo: window.location.origin
      });

      // this.$store.dispatch('logout')
    },
    async getUserAccess() {
      try {
        console.log("User email: ", this.$auth);
        if (this.$auth) {
          console.log("User email user: ", this.$auth.user);
        } 
      } catch (error) {
        console.log("calling from getUserAccess, error: ", error)
      }
      // try {
      //   var email = await this.$auth.user.email
      //   console.log("User email: " + email); 
      //   var test = await this.$auth.loading
      //   if (test) {
      //     var test = await this.$auth.loading
      //     var test1 = this.$auth.isAuthenticated
      //     var test2 = await this.$auth
      //     console.log("Auth loading is done!, test: ", test, ", test1: ", test1, ", test2: ", this.$auth.user.email)

      //     await axios
      //       .get(`${window.hostname}/getapplicationuser/${this.$auth.user.email}`)
      //       .then(res => {
      //           if(res.data.error){
      //               this.errorMsg = res.data.message;
      //           }
      //           else{
      //               console.log("I am inside getAllApplications")
      //               this.user = res.data[0];
      //               console.log("this.user: ", this.$auth.user.email)
      //           }
      //       })
      //       .catch(error => {
      //           console.log(error)
      //       })
      //   }
      // } catch (error) {
      //   console.log("I am here, error: ", error)
      // }
    }
  },

  mounted() {
    this.getUserAccess();
  }
};
</script>

<style lang="scss" scoped>
</style>
