import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMask from 'v-mask';
import VueCryptojs from 'vue-cryptojs'
import VueFormGenerator from 'vue-form-generator'
import TelInput from './components/Utils/tel-input.vue'
import Cleave from 'cleave.js';
import moment from 'moment'

//Authentication code start
// Import the Auth0 configuration
import { domain, clientId, audience } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";

Vue.use(VueFormGenerator);

// Vue.use(VueLoading)


Vue.directive('cleave', {
  inserted: (el, binding) => {
      el.cleave = new Cleave(el, binding.value || {})
  },
  update: (el) => {
      const event = new Event('input', {bubbles: true});
      setTimeout(function () {
          el.value = el.cleave.properties.result
          el.dispatchEvent(event)
      }, 100);
  }
});

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm')
    // return moment(String(value)).format('YYYY/MM/DD hh:mm')
  }
});

// Install the authentication plugin here
Vue.use(VueCryptojs);

Vue.use(VueFormGenerator, {
  validators: {
    strongPassword: (value) => {
      let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{5,}$/

      if (!regex.test(value)) {
        return [ 'Password should be a minimum five characters, at least one uppercase letter, ' +
        'one lowercase letter and one number']
      } else {
        return []
      }
    }
  }
})

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  VueMask,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});
//Authentication code end

Vue.component('field-tel-input', TelInput)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
