<template>
    <div>
        <b-card
            style="max-width: 35rem;"
            class="mx-auto mt-5"
        >
            <b-card-title style="align:center" class="pb-0">
                <h1>Morgan Fmily Trust Login</h1>
            </b-card-title>
            <b-card-text>
                <b-form @submit.prevent="submitApplication" @reset="onReset">
                    <b-form-group
                        label="Email:"
                        label-for="nested-email"
                    >
                        <b-form-input 
                            id="nested-email"
                            name="email"
                            type="email"
                            v-model="$v.ApplicationFormData.email.$model"
                            aria-describedby="input-email-feedback"
                            placeholder="Enter email address"
                            :state="validateState('email')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-email-feedback">
                            Please enter a valid email address.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        label="Password:"
                        label-for="nested-password"
                    >
                        <b-form-input 
                            id="nested-password"
                            name="password"
                            type="password"
                            v-model="$v.ApplicationFormData.password.$model"
                            aria-describedby="input-password-feedback"
                            :state="validateState('password')"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-password-feedback">
                            This is a required field.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-button-group>
                        <b-button type="submit" variant="primary" class="mr-2">Submit</b-button>
                        <b-button type="reset" variant="danger">Reset</b-button>
                    </b-button-group>
                </b-form>
            </b-card-text>
            
            <!-- <b-button-group>
                <b-button type="submit" variant="primary" class="mr-2">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
            </b-button-group> -->
        </b-card>
    </div>
</template>

<script>
  import axios from 'axios'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  import { validationMixin } from "vuelidate";
  import { required, minLength, maxLength, between, helpers } from 'vuelidate/lib/validators'
  const phoneNumberValidator = helpers.regex('phoneNumberValidator', /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
  const ssnValidator = helpers.regex('ssnValidator', /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/)

  export default {
    mixins: [validationMixin],
    data() {
      return {
        fullname: '',
        ssn: '',
        dateOfBirth: '',
        show: true,

        ApplicationFormData: {
            email: "",
            password: ""
        },
        isSaving: false,

        visible: false,
        fullPage: true
      }
    },
    components: {
        Loading
    },
    validations: {
        ApplicationFormData: {
            email: {
                required
                //email
            },
            password: {
                required,
                minLength: minLength(3)
            }
        }
    },
    mounted: function() {
        this.pageLoadProcessing();
    },
    methods: {
        increment() {
            this.$store.commit('increment')
            console.log(this.$store.state.count)
        },
        pageLoadProcessing() {
            this.visible = true;
            // simulate AJAX
            setTimeout(() => {
                this.visible = false
            },2000)
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.ApplicationFormData[name];
            return $dirty ? !$error : null;
        },
        validateBeforeSubmit() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                // eslint-disable-next-line
                    alert('Form Submitted!');
                    return;
                }

                alert('Correct them errors!');
            });
        },
        onSubmit(event) {
            alert("I am here!")
            this.$v.ApplicationFormData.$touch();
            if (this.$v.ApplicationFormData.$anyError) {
                return;
            }

            alert("Form submitted!");
        },
        onReset(event) {
            event.preventDefault()
            // Reset our form values
            this.ApplicationFormData.email = ''
            this.ApplicationFormData.password = ''

            // Trick to reset/clear native browser form validation state
            this.show = false
            this.$nextTick(() => {
            this.show = true
            })
        },
        async submitApplication (event) {
            console.log("ApplicationFormData: ", this.ApplicationFormData)
            //this.increment();
            this.$v.ApplicationFormData.$touch();
            if (this.$v.ApplicationFormData.$anyError) {
                return;
            }

            console.log("ApplicationFormData 2: ", this.ApplicationFormData, ", test: ", process.env.VUE_APP_MFT_TEMP_EMAIL)
            if (this.ApplicationFormData.email != process.env.VUE_APP_MFT_TEMP_EMAIL || this.ApplicationFormData.password != process.env.VUE_APP_MFT_TEMP_PASSWORD) {
                alert("Email or password is wrong. Please try again!")
                return;
            }
            else {
                this.increment();
            }
            
            event.preventDefault()
            this.isSaving = true;
            // try {
            //     alert(JSON.stringify(this.ApplicationFormData))

            //     await axios.post(`${window.hostname}/apply/${this.$auth.user.email}`, this.ApplicationFormData)
            //         .then(res => {
            //             alert('Your application is submitted successfully!');
            //             if (res.status === 200) {
            //                 this.$router.push({ path : '/applicationstatus' });
            //             }
            //         }
            //     );
            // } catch (e) {
            //     alert(e.message)
            // }
            this.isSaving = false
        }
    },
    computed: {
    },
  }
</script>

<style scoped>
/* #application-form {
    width: calc(100% - 4em);
    position: relative;
}

.card {
  position: relative;
  width: 100%;
  height: auto;
  border: #ffffff solid 1px;
  margin: 2em;
  background-color: hsla(0, 0%, 100%, 0.1);
  border: 0;
  border-radius: 7px;
  position: relative;
  margin-bottom: 24px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
}

#requisition-form-fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

#apply-form-content {
  margin-bottom: 80px;
} */
</style>