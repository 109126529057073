<template>
  <div id="app">
    <!-- <div class="page" v-if="showSpinner">
      <b-spinner class="spinner" variant="primary" key="primary"></b-spinner>
    </div> -->
    <!-- <div id="nav">
      <top-header></top-header>
    </div> -->
    
    <div id="MainWrapper">
      <div id="MainContent">
        <!-- <Login></Login> -->

        <!-- <div v-if="this.$store.state.count>0"> -->
        <div v-if="this.$store.state.count>0">
          <top-header :show_admin_menu="show_admin_menu"></top-header>
          <router-view />
          <Footer></Footer>
        </div>
        
        <div v-if="this.$store.state.count==0">
          <Login></Login>
        </div>
        
      </div>
    </div>
    
  </div>
</template>
<!--
-change text decoration
-keep navbar inside container
-add fixed height(it creates scrolbar) and overflow auto
-align the text and change font
-npm run lint --fix
 -->

<script>
//var PROD_ADDRESS = "";
//var PROD_DEV_ADDRESS = "http://changethisip/";
//var DEV_ADDRESS_1 = "http://127.0.0.1:5001/api";  // Development level  
var DEV_ADDRESS_1 = "https://morganfamily.net/api";  // Production level
//var DEV_ADDRESS_1 = "http://64.227.102.34:5001";
//var PROD_MODE = false;

window.hostname = DEV_ADDRESS_1;

import Login from "@/components/Login.vue";
import TopHeader from "@/components/TopHeader.vue";
import Footer from "@/components/Footer.vue";
import { mapGetters } from "vuex";
import axios from 'axios';
// import Login from './components/Login.vue';
// import 'vue-form-generator/dist/vfg.css'

export default {
  name: "App",
  computed: {
    ...mapGetters(["showSpinner"])
  },
  data() {
    return {
      user: '',
      show_admin_menu: true
    }
  },
  components: {
    "top-header": TopHeader,
    Footer,
    Login
  },
  methods: {
    async getUserAccess() {
      await axios
        .get(`${window.hostname}/getapplicationuser/${this.$auth.user.email}`)
        .then(res => {
            if(res.data.error){
            }
            else{
              console.log("Response from getUserAccess, res: ", res)
              console.log("I am inside getUserAccess, ${this.$auth.user.email}: ", this.$auth.user)
              this.user = res.data[0];
              console.log("this.user: ", this.user)

              if (this.user) {
                this.show_admin_menu = true;
              }
              else {
                this.show_admin_menu = false;
              }
            }
        })
        .catch(error => {
            console.log(error)
        })
    }
  },
  mounted() {
    //var self = this;
    //this.getUserAccess();
    //this.$store.state.count = 0;
    //this.$store.commit('decrement')
    
    //console.log("count: ", this.$store.state.count)
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar
{
  width: 20px;
}

::-webkit-scrollbar-track
{
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb
{
  background: rgba(0, 0, 0, 0.5);
}
html {
  height: 100%;
}

body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #292b2c;
  background-color: #fff;
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  height: 100%;
  display: flex;
}

#MainWrapper {
  // margin-top: 3.5em;
  // height: calc(100% - 3.5em);
  height: 100%;
  width: 100%;
  display: flex;
}

#MainContent {
  display: inline-block;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: #4780C3;
}

</style>
