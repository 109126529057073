<template>
  <b-container fluid id="HomeContent">
    <b-row>
      <b-col>
        <Carousel></Carousel>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <FamilyNews
          header="Family News"
        ></FamilyNews>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <CollegeGrantNews
          header="College Grant Info"
          content=""
        ></CollegeGrantNews>
      </b-col>
      <b-col>
          <BirthdayNews
            header="Upcoming Birthdays"
          ></BirthdayNews>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <MarriageNews
          header="Marriages"
          content=""
        ></MarriageNews>
      </b-col>
      <b-col>
        <DeathNews
          header="Deaths"
          content=""
        ></DeathNews>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src

//import JobCard from "@/components/JobCard.vue";
import Carousel from "@/components/Carousel.vue";
import BirthdayNews from "@/components/Homepage/BirthdayNews.vue";
import FamilyNews from "@/components/Homepage/FamilyNews.vue";
import CollegeGrantNews from "@/components/Homepage/CollegeGrantNews.vue";
import MarriageNews from "@/components/Homepage/MarriageNews.vue";
import DeathNews from "@/components/Homepage/DeathNews.vue";

import { mapGetters } from "vuex";

export default {
  name: "Home",
  //components: { "job-card": JobCard, Carousel },
  components: { Carousel, BirthdayNews, FamilyNews, CollegeGrantNews, MarriageNews, DeathNews },
  // components: { Carousel, FamilyNews, CollegeGrantNews, MarriageNews, DeathNews },
  computed: {
    ...mapGetters(["jobs", "displayJobs", "rows"])
  },
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      //jobs: [],
      //displayJobs: [],
      currentPage: 1,
      //rows: 1,
      perPage: 3
    };
  },
  methods: {
    // onScroll(event) {
    //   console.log(this.$refs.scrollbar.ps, event);
    // },
    async fetchData() {
      //add error handling
      await this.$store.dispatch("fetchJobs");
      //console.log("test", this.$store.getters.jobs);
      //const res = await fetch("jobs.json");
      //const val = await res.json();
      //this.jobs = this.jobs;
      //this.displayJobs = this.jobs.slice(0, 3);
      //this.rows = this.jobs.length;
      //console.log(this.jobs);
    },

    paginate(currentPage) {
      //const start = (currentPage-1)*this.perPage;
      //this.displayJobs = this.jobs.slice(start, start+3);
      //alert(currentPage);
      this.$store.dispatch("paginate", { currentPage, perPage: this.perPage });
    },

    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    }
  }
};
</script>

<style lang="scss" scoped>
.my-row {
  height: 480px;
}

#HomeContent {
  margin-bottom: 50px;
}

// Mixin itself
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

// Usage as a mixin
.element {
  @include clearfix;
}

// .ps {
//   height: 300px;
// }
</style>
