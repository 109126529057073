import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios';

import auth0 from "auth0-js";
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'


Vue.use(Vuex);

export default new Vuex.Store({
  // plugins: [
  //   createPersistedState({
  //     getState: (key) => Cookies.getJSON(key),
  //     setState: (key, state) => Cookies.set(key, state, { expires: 3, secure: true })
  //   })
  // ],
  // plugins: [
  //   createPersistedState()
  // ],
  plugins: [createPersistedState({
    storage: {
      getItem: key => Cookies.get(key),
      setItem: (key, value) => Cookies.set(key, value, { expires: 1, secure: true }),
      removeItem: key => Cookies.remove(key)
    }
  })],
  namespaced: true,
  state: {
    jobs: [],
    displayJobs: [],
    rows: 0,
    showSpinner: false,
    count: 0,

    userIsAuthorized: false,
    // auth0: new auth0.WebAuth({
    //   domain: process.env.VUE_APP_AUTH0_CONFIG_DOMAIN,
    //   clientID: process.env.VUE_APP_AUTH0_CONFIG_CLIENTID,
    //   redirectUri: process.env.VUE_APP_DOMAINURL_REDIRECT,
    //   responseType: process.env.VUE_APP_AUTH0_CONFIG_RESPONSETYPE,
    //   scope: process.env.VUE_APP_AUTH0_CONFIG_SCOPE
    // })
  },
  mutations: {
    SET_JOBS(state, jobs) {
      state.jobs = jobs;
    },
    SET_ROWS(state, rows) {
      state.rows = rows;
    },
    SET_DISPLAY_JOBS(state, displayJobs) {
      state.displayJobs = displayJobs;
    },
    SET_SPINNER(state, spinner) {
      state.showSpinner = spinner;
    },
    increment (state) {
      state.count++
    },
    decrement (state) {
      state.count--;
    }
  },
  actions: {
    async fetchData({ commit }) {
      commit("SET_SPINNER", true);
      return new Promise(resolve => {
        setTimeout(async () => {
          const res = await fetch("jobs.json");
          const val = await res.json();
          resolve(val);
          commit("SET_SPINNER", false); //add try catch
        }, 1000);
      });
    },
    async fetchJobs({ dispatch, commit }) {
      const myJson = await dispatch("fetchData");
      commit("SET_JOBS", myJson);
      commit("SET_ROWS", myJson.length);
      const displayJobs = myJson.slice(0, 3);
      commit("SET_DISPLAY_JOBS", displayJobs);
      commit("SET_ROWS", myJson.length);
    },
    async paginate({ commit, state }, { currentPage, perPage }) {
      //console.log(perPage);
      //console.log(currentPage);
      const start = (currentPage - 1) * perPage;
      const jobs = state.jobs.slice(start, start + 3);
      commit("SET_DISPLAY_JOBS", jobs);
    },
    updatePagination({ commit, dispatch }, { myJson, currentPage, perPage }) {
      commit("SET_JOBS", myJson);
      commit("SET_ROWS", myJson.length);
      dispatch("paginate", { currentPage, perPage });
    },

    async search({ dispatch }, { text }) {
      const myJson = await this.dispatch("fetchData");
      const values = myJson.filter(val =>
        val.name.toLowerCase().includes(text.toLowerCase())
      );
      dispatch("updatePagination", {
        myJson: values,
        currentPage: 1,
        perPage: 3
      });
    }
  },
  getters: {
    jobs(state) {
      return state.jobs;
    },
    rows(state) {
      return state.rows;
    },
    displayJobs(state) {
      return state.displayJobs;
    },
    showSpinner(state) {
      return state.showSpinner;
    }
  },
  modules: {}
});
