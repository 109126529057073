<template>
    <div>
        <!-- <b-card no-body class="overflow-hidden"> -->
        <b-card>
            <b-row no-gutters>
                <b-col md="3">
                    <!-- <b-card-img src="https://picsum.photos/400/400/?image=20" alt="Image" class="rounded-0"></b-card-img> -->
                    <b-card-img style="height: 400px" src="/images/Kyle_Kouri_1.jpeg" alt="Image" class="rounded-2"></b-card-img>
                    <b-card-text style="font-weight:bold;">Todd Family Board Member: Kyle Kouri<br>Kyle started on the board in 2020.</b-card-text>
                </b-col>
                <b-col md="9">
                    <b-card-body title="Todd Family News">
                        <b-card-text>
                            <p>
                                Since your invitation to the reunion in Denver may have come as a surprise to most of you, I am writing to fill you in a little on the background. In the early 1990's, my Uncle Burt and Aunt Peg Morgan decided to start a family group to meet periodically and manage a family “nest egg” of money for emergency use. In the beginning it was to be a family business partnership and the group was to be about 50 people. However, it soon became apparent that they could not maintain control over the partnership shares and see that they would be passed along, so Uncle Burt decided to turn it into a foundation. But since Uncle Burt's planning procedure was “Fire, Aim, Ready”, he did not consult his attorney until after he had made some arrangements, and only then did he find out that he could not use charitable foundation to benefit anyone in his own family. So then he had to move on to a third plan, which was to set up a family trust. So at his third family gathering, he had a discussion about the trust idea, but unfortunately there was a lot of strife over the initial funding, a lot of diverse opinion about what the family money should be used for, and a lot of confusion about who would participate in the family group. Even more unfortunately, Uncle Burt died 5 months later before the plan could be completed. 
                            </p>
                            <p>
                                The plan for the family trust idea had included the establishment of a board of trustees to manage things, so at least there were people able to take over after Uncle Burt's death. It was finally decided that the family group would include all the descendants of the grandparents of Burt Morgan and Margaret Clark Morgan. That meant the group size grew to around 300, and there were some eligible people who had not yet been counted or located. 
                            </p>
                            <p>
                                I did not know most of Uncle Burt's relatives. On Aunt Peggy's side, they had included the Clark cousins because the Morgans live in Hudson where the Clarks and Browns settled and had a long history. They were not as familiar with the Todd cousins from New England, but my mother and I were, because Grandmom (Ethel Todd Clark) had spent much time telling stories of her childhood in New England to my mother and me, and we were the ones who had moved back to New England (Vermont) to live.
                            </p>
                            <p>
                                The long and the short of it is, when I found out how the Morgan Family Trust group was to be defined. I asked the managers if all the Todds would be eligible under the family definition, and I finally learned they would be. So now you have your invitations to the Denver Reunion, which is actually the fourth gathering that has been held. I went to 2 of the others while Uncle Burt was still living. 
                            </p>
                            <p>
                                There are 2 basic purposes for the Morgan Family Trust - one is to continue the family “nest egg” of money with the current priority being to assist college attendees with tuition bills, and the other is to provide planning and financial assistance for everyone to attend a reunion gathering about every 3 years in different locations around the country.
                            </p>
                            <p>
                                I am enclosing a brief story of the Todds that I wrote to send with your addresses to the Morgan Family Trust (MFT) board to get you invited. I have tentatively signed up for me and my 2 boys to go, but I don't think my Mom can make a trip that far this year, so we will have to have arrangements for her at home in order for us to go. 
                            </p>
                        </b-card-text>
                    </b-card-body>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: 'ToddFamily',
        data () {
            return {
            }
        }
    };
</script>

<style scoped>
/* #family-page {
    width: calc(100% - 4em);
    position: relative;
} */
.b-card {
    position: relative;
    width: 100%;
    height: auto;
    border: #ffffff solid 1px;
    margin: 2em;
    background-color: hsla(0, 0%, 100%, 0.1);
    border: 0;
    border-radius: 7px;
    position: relative;
    margin-bottom: 24px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
}
</style>
