import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { authGuard } from "../auth/authGuard";

import UserProfile from '../components/Profile/UserProfile';
import CreateUser from '../components/Profile/CreateUser';
import EditUser from '../components/Profile/EditUser';
import ToddFamily from '../views/FamilyPages/ToddFamily';


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/About.vue"),
    beforeEnter: authGuard
  },
  {
    path: "/admin",
    name: "Admin",
    component: () =>
    import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
    beforeEnter: authGuard
  },
  {
    path: "/toddfamily",
    name: "ToddFamily",
    component: ToddFamily,
  },
  {
    path: "/grantinfo",
    name: "GrantInfo",
    component: () =>
    import(/* webpackChunkName: "admin" */ "../views/CollegeGrants/GrantInfo.vue")
  },
  {
    path: "/apply",
    name: "Apply",
    component: () =>
    import(/* webpackChunkName: "admin" */ "../views/CollegeGrants/Apply.vue"),
    beforeEnter: authGuard
  },
  {
    path: "/applicationreview",
    name: "ApplicationReview",
    component: () =>
    import(/* webpackChunkName: "admin" */ "../views/CollegeGrants/ApplicationReview.vue"),
    beforeEnter: authGuard
  },
  {
    path: "/applicationstatus",
    name: "ApplicationStatus",
    component: () =>
    import(/* webpackChunkName: "admin" */ "../views/CollegeGrants/ApplicationStatus.vue"),
    beforeEnter: authGuard
  },
  {
    path: "/usermanagement",
    name: "UserManagement",
    component: () =>
    import(/* webpackChunkName: "admin" */ "../views/AdminPanel/UserManagement.vue"),
    beforeEnter: authGuard
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
    import(/* webpackChunkName: "admin" */ "../views/Users.vue")
    //beforeEnter: authGuard
  },
  {
    path: "/profile",
    name: "UserProfile",
    // component: () =>
    // import(/* webpackChunkName: "admin" */ "../views/UserProfile.vue"),
    component: UserProfile,
    beforeEnter: authGuard
  },
  {
    path: '/createprofile',
    name: 'CreateUser',
    component: CreateUser,
    beforeEnter: authGuard
  },
  {
    path: '/editprofile',
    name: 'EditUser',
    component: EditUser,
    beforeEnter: authGuard
  }
];

const router = new VueRouter({
  mode: "history",
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

export default router;
