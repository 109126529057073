import { render, staticRenderFns } from "./UserProfile.vue?vue&type=template&id=d230d41a&scoped=true&"
import script from "./UserProfile.vue?vue&type=script&lang=js&"
export * from "./UserProfile.vue?vue&type=script&lang=js&"
import style0 from "./UserProfile.vue?vue&type=style&index=0&id=d230d41a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d230d41a",
  null
  
)

export default component.exports