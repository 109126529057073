<template>
    <div class="container">
        <!-- <div class="d-flex justify-content-start mb-3">
            <router-link to="/profile" class="btn btn-sm btn-outline-secondary">back</router-link>
        </div> -->
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column align-items-start">
                <h2>Edit Profile</h2><span class="text-muted"></span>
            </div>
        </div>
        <div class="card pl-4 pt-5 pb-5 pr-4 mt-5">
            <form action="" @submit.prevent="updateUserDetails">
                <vue-form-generator tag="div" :schema="schema" :model="model[0]" />
                <div class="d-flex justify-content-end mt-3 pr-4">
                    <button class="btn btn-primary btn-lg">
                        {{ isSaving ? 'Saving...' : 'Update'}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    // import userFormSchema from '../forms/userFormSchema'
    import userFormSchema from '../../forms/userFormSchema'
    import axios from 'axios'
    // import 'vue-form-generator/dist/vfg.css'

    export default {
        name: 'EditUser',
        mounted () {
            //this.fetchUser(this.$route.params.id)
            this.fetchUser()
        },
        data () {
            return {
                model: {},
                schema: userFormSchema,
                id: this.$route.params.id,
                isSaving: false
            }
        },
        methods: {
            async fetchUser () {
                try {
                    console.log("User email: " + `${this.$auth.user.email}`); 
                    // this.model = (await axios.get(`http://localhost:5000/userInfo/${this.$auth.user.email}`)).data
                    this.model = (await axios.get(`${window.hostname}/userInfo/${this.$auth.user.email}`)).data
                    console.log("model: " + Object.entries(this.model[0]));
                } catch (e) {
                    console.log(e);
                    alert(e.message)    
                }
            },
            async updateUserDetails () {
                this.isSaving = true;
                try {
                    //console.log("Model: " + Object.entries(this.model[0]));
                    // await axios.put(`http://localhost:5000/userInfo/${this.$auth.user.email}`, this.model[0]);
                    await axios.put(`${window.hostname}/userInfo/${this.$auth.user.email}`, this.model[0]).then(res => {
                        alert('Your profile is updated!')
                        if (res.status === 200) {
                            this.$router.push({ path : '/' });
                        }
                    });
                } catch (e) {
                    console.log(e);
                    alert(e.message)
                }
                this.isSaving = false
            }
        }
    }
</script>

<style scoped>
body {
   background-color: #fafafa !important;
}

.vue-form-generator > div{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 1;
}

.form-group{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 2%;
  width: 50%;
}

.field-wrap, .wrapper{
  width: 100%;
}

.dropList{
  z-index: 10;
  background-color: #FFF;
  position: relative;
  width: 40%;
  top: 5px;
  right: 12px;
}

legend{
  margin: 10px 0 20px 18px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.hint{
  font-size: 10px;
  font-style: italic;
  color: purple;
}

.help-block{
  color: red;
}
</style>