<template>
  <div>
    <b-jumbotron>
      <template v-slot:header>
        <header class="header">{{ header }}</header>
      </template>

      <!-- <template v-slot:lead>
        <lead class="alegreya">
          {{ content }}
        </lead>
      </template> -->

      <template class="alegreya" #lead>
        {{ content }}
      </template>
      
      <hr class="my-4">
      
      
      <section v-if="errored">
        <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
      </section>
      <section v-else>
          <div v-if="loading">Loading...</div>
          <div v-else>
            <perfect-scrollbar @ps-scroll-y="onScroll" ref="scrollbar">
              <ul v-for="birthday in birthdays" v-bind:key="birthday.id" style="list-style-type: circle; text-align:left;">
                  <li>{{birthday.description}}</li>
              </ul>
            </perfect-scrollbar>
          </div>
      </section>

    </b-jumbotron>
  </div>
</template>

<script>
import axios from 'axios';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';

export default {
  props: ["header", "content"],
  components: { PerfectScrollbar },
  data: function() {
    return {
      color: "white",
      birthdays: null,
      loading: true,
      errored: false
    };
  },
  methods: {
    onScroll(event) {
      console.log(this.$refs.scrollbar.ps, event);
    },
  },
  created: function() {
    axios
        .get(`${window.hostname}/admin/3`)
        .then(res => {
            console.log(res.data);
            this.birthdays = res.data;
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.loading = false)
    }
};
</script>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>
<style lang="scss" scoped>
.serif {
  font-family: "Times New Roman", Times, serif;
}

.sansserif {
  font-family: Arial, Helvetica, sans-serif;
}

.monospace {
  font-family: "Lucida Console", Courier, monospace;
}

.comicsansserif {
  font-family: "Comic Sans MS", cursive, sans-serif;
}

.alegreya {
  font-family: "Alegreya";
}

.header {
  font-family: "Comic Sans MS", cursive, sans-serif;
  text-align: center;
}

.ps {
  height: 200px;
}

</style>
