<template>
  <div class="footer">
    &copy; 2020 Copyright:
    <a href="http://www.morganfamilytrust.net/"> Morgan Family Trust </a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
// .footer{
//   background-color: aqua;
//   padding: 25px 0px 25px 0px;
// }

// copied
// .footer {
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   height: 100px;
//   width: 100%;
// }

.footer {
  position: absolute;
  background-color: aqua;
  left: 0;
  bottom: 0;
  padding: 15px 0px 15px 0px;
  width: 100%;
  text-align: center;
}
</style>
