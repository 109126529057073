<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      img-width="1024"
      img-height="250"
      background="#ababab"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
      class="mx-auto"
    >
      <!-- Text slides with image -->
      <!-- <b-carousel-slide
        caption="First slide"
        text="Nulla vitae elit libero, a pharetra augue mollis interdum."
        img-src="@/assets/Morgan_Family_Reunion_33.jpg"
        img-width="1024"
        img-height="480"
      ></b-carousel-slide> -->

      <!-- <b-carousel-slide>
        <template v-slot:img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="@/assets/Morgan_Family_Reunion_33.jpg"
            alt="image slot"
          />
        </template>
      </b-carousel-slide> -->

      <b-carousel-slide
        img-src="@/assets/temp_1.png" 
      ></b-carousel-slide>

      <!-- Slides with custom text -->
      <b-carousel-slide img-src="@/assets/temp_2.png">
      </b-carousel-slide>

      <!-- Slides with image only -->
      <b-carousel-slide
        img-src="@/assets/temp_3.png"
      ></b-carousel-slide>

      <!-- Slides with img slot -->
      <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
      <!-- <b-carousel-slide>
        <template v-slot:img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="@/assets/Morgan_Family_Reunion_118.jpg"
            alt="image slot"
          />
        </template>
      </b-carousel-slide> -->

      <b-carousel-slide
        img-src="@/assets/temp_4.png"
      ></b-carousel-slide>

    </b-carousel>

    <p class="mt-4">
      <!-- Slide #: {{ slide }}<br>
      Sliding: {{ sliding }} -->
    </p>

  </div>
</template>

<script>
export default {
  data() {
    return {
      slide: 0,
      sliding: null
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>

